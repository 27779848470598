/*
=========================================================
* GetFitter - The Ultimate Health & Fitness Companion
=========================================================

* Revolutionize your health journey with AI-driven fitness, nutrition, and workout tracking.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
// import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKButton from "components/MKButton";
// import MKTypography from "components/MKTypography";
import IconButton from "@mui/material/IconButton";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

function SimpleModal() {
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const VideoEmbed = () => {
    return (
      <iframe
        src="https://share.synthesia.io/embeds/videos/5b5dfdf5-3090-4010-bbe5-e65ecb439161"
        loading="lazy"
        title="Synthesia video player - Transform Your Fitness Journey with GetFitter"
        allowFullScreen
        allow="encrypted-media; fullscreen;"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          border: "none",
          padding: 0,
          margin: 0,
          overflow: "hidden",
        }}
      ></iframe>
    );
  };

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid container item xs={12} lg={10} justifyContent="center" mx="auto">
          <IconButton
            type="button"
            onClick={toggleModal}
            sx={{ color: "red", fontSize: "5rem", animation: "pulse 8s infinite" }}
          >
            <PlayCircleIcon fontSize="inherit" />
          </IconButton>
        </Grid>
        <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={show} timeout={500}>
            <MKBox
              position="relative"
              width="100%"
              height="100%"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              {/* <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">Watch Our Introduction</MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
              </MKBox> */}
              <Divider sx={{ my: 0 }} />
              <MKBox p={2} display="flex" justifyContent="center">
                <VideoEmbed />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              {/* <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                  Close
                </MKButton>
              </MKBox> */}
            </MKBox>
          </Slide>
        </Modal>
      </Container>
    </MKBox>
  );
}

export default SimpleModal;
