// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "GetFitter",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/profile.php?id=61573064168075",
    },
    // {
    //   icon: <TwitterIcon />,
    //   link: "https://twitter.com/creativetim",
    // },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/gfitterdotcom",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/@gfitterdotcom",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "Apps", href: "/apps" },
        // { name: "blog", href: "https://www.creative-tim.com/blog" },
      ],
    },
    {
      name: "resources",
      items: [
        {
          name: "Apple store",
          href: "https://apps.apple.com/us/app/getfitter-train-with-ai/id6468882341?platform=iphone",
        },
        {
          name: "Play store",
          href: "https://play.google.com/store/apps/details?id=com.cognotech.getfitter",
        },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "/pages/landing-pages/contact-us" },
        // { name: "knowledge center", href: "/pages/landing-pages/knowledge-center" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", href: "/pages/terms" },
        { name: "privacy policy", href: "/pages/privacy" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} COGNOTECH
    </MKTypography>
  ),
};
