/*
=========================================================
* GetFitter - The Ultimate Health & Fitness Companion
=========================================================

* Revolutionize your health journey with AI-driven fitness, nutrition, and workout tracking.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

// Images
import bgImage from "assets/images/health-fitness.jpg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="fitness_center"
                    title="Workout & Exercise Tracking"
                    description="Enhance your workouts with intelligent insights, technique video correction, and rep tracking."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="assistant"
                    title="Scan Barcode & Search"
                    description="Scan or search for food products to track their nutritional values. GetFitter searches across all types of food products."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="calculate"
                    title="AI Macro Calculator"
                    description="Simply say what you've consumed, and our AI will calculate the nutritional values."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="bar_chart"
                    title="Macro & Calorie Tracking"
                    description="Visually monitor your calorie intake with intuitive charts to optimize your nutrition."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={bgImage}
              title="Join GetFitter for FREE"
              description="Track progress, discover workouts, and enjoy a healthier lifestyle with over 2 million food products and hundreds of exercises."
              action={{
                type: "internal",
                route: "pages/app/features",
                color: "info",
                label: "Explore Features",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
